import { useQuery } from '@tanstack/react-query';
import GSpinner from '../components/common/g-spinner';
import GContainer from '../components/ui/g-container';
import GYouTube from '../components/ui/g-you-tube';
import { useGQuery } from '../hooks/use-g-query';
import { useTitle } from '../hooks/use-title';
import { IVideo, IVideoDetail } from '../models';
import { supabase } from '../supabase-client';

export function GVideoList() {
  const { data, error } = useQuery(['video_details'], async () => {
    const { data } = await supabase.from<IVideoDetail>('video_details').select('id,youtube_id,youtube_title').not('youtube_id', 'is', null);

    return data;
  });

  if (error) return <h1>'An error has occured'</h1>;
  if (!data)
    return (
      <div className="flex items-center justify-center">
        <GSpinner />
      </div>
    );
  return (
    <ul className="grid grid-cols-2 px-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
      {data.map((v) => (
        <li key={v.id} className="relative">
          <div className="block w-full overflow-hidden bg-gray-100 rounded-lg group aspect-w-10 aspect-h-7 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
            <GYouTube uid={v.youtube_id} title={v.youtube_title} />
          </div>
        </li>
      ))}
    </ul>
  );
}

const Videos = () => {
  useTitle('Videos');
  let query = useGQuery();
  const l = query.get('l') ?? 'en';

  const { data, error } = useQuery(['videos', l], async () => {
    const { data } = await supabase.from<IVideo>('videos').select('*').eq('lang', l).single();

    return data;
  });

  if (error) return <h1>'An error has occured'</h1>;
  if (!data) return <GSpinner />;

  return (
    <>
      <img src={data?.image1} alt="gallery" />
      <GContainer>
        <div className="px-2 py-4 text-center">
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 uppercase dark:text-white sm:text-4xl">{data.title}</h2>
          <p className="max-w-2xl mx-auto mt-3 text-xl text-gray-500 dark:text-gray-400 sm:mt-4">{data.description}</p>
        </div>
        <GVideoList />
      </GContainer>
    </>
  );
};

export default Videos;
