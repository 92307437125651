import { ReactNode } from 'react';

export function GMailto({ email, subject, body, children, className }: { email: string; subject?: string; body?: string; children?: ReactNode; className?: string }) {
  return (
    <a href={`mailto:${email}?subject=${subject || ''}&body=${body || ''}`} className={className}>
      {children}
    </a>
  );
}

export default GMailto;
