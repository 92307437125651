import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline';
import { IClaimContact } from '../../models';

export const GContact = ({ contact }: { contact: IClaimContact }) => {
  return (
    <div className="col-span-1 bg-gray-100 divide-y divide-gray-200 rounded-lg shadow dark:divide-gray-700 dark:bg-gray-800">
      <div className="flex items-center justify-between w-full p-6 space-x-6">
        <div className="flex-1 truncate">
          <div className="flex items-center space-x-3">
            <h3 className="text-sm font-medium text-gray-900 uppercase truncate dark:text-white">{contact.title}</h3>
          </div>
        </div>
      </div>
      <div className="py-2 space-y-2">
        <div className="relative px-4 overflow-hidden sm:px-6">
          <dt>
            <div className="absolute p-3 rounded-md bg-orange dark:bg-gray-700">
              <EnvelopeIcon className="w-5 h-5 text-white dark:text-gray-300" aria-hidden="true" />
            </div>
            <p className="ml-16 text-sm text-gray-500 truncate dark:text-gray-400">{contact.email.label}</p>
          </dt>
          <dd className="flex items-baseline ml-16">
            <p className="text-base font-semibold text-orange dark:text-gray-300">{contact.email.value}</p>
          </dd>
        </div>

        <div className="relative px-4 overflow-hidden sm:px-6">
          <dt>
            <div className="absolute p-3 rounded-md bg-orange dark:bg-gray-700">
              <PhoneIcon className="w-5 h-5 text-white dark:text-gray-300" aria-hidden="true" />
            </div>
            <p className="ml-16 text-sm text-gray-500 truncate dark:text-gray-400">{contact.helpline.label}</p>
          </dt>
          <dd className="flex items-baseline ml-16">
            <p className="text-base font-semibold text-orange dark:text-gray-300">{contact.helpline.value}</p>
          </dd>
        </div>

        <div className="relative px-4 overflow-hidden sm:px-6">
          <dt>
            <div className="absolute p-3 rounded-md bg-orange dark:bg-gray-700">
              <PhoneIcon className="w-5 h-5 text-white dark:text-gray-300" aria-hidden="true" />
            </div>
            <p className="ml-16 text-sm text-gray-500 truncate dark:text-gray-400">{contact.tel.label}</p>
          </dt>
          <dd className="flex items-baseline ml-16">
            <p className="text-base font-semibold text-orange dark:text-gray-300">{contact.tel.value}</p>
          </dd>
        </div>
      </div>
      <div>
        <div className="flex items-center justify-between w-full px-6 py-6">
          <div className="px-4 py-2">
            <dt className="text-sm font-medium text-gray-500 truncate dark:text-gray-400">{contact.cf1.title}</dt>
            <dd className="mt-1 text-base font-semibold text-gray-900 dark:text-gray-300">
              <a target="_blank" href={contact.cf1.url} download rel="noreferrer">
                {contact.cf1.label}
              </a>
            </dd>
          </div>
          {contact.cf2 && (
            <div className="px-4 py-2">
              <dt className="text-sm font-medium text-gray-500 truncate dark:text-gray-400">{contact.cf2.title}</dt>
              <dd className="mt-1 text-base font-semibold text-gray-900 dark:text-gray-300">
                <a target="_blank" href={contact.cf2.url} download rel="noreferrer">
                  {contact.cf2.label}
                </a>
              </dd>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GContact;
