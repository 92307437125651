import { useQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';
import GSpinner from '../components/common/g-spinner';
import GContainer from '../components/ui/g-container';
import GProductHeroGrid from '../components/ui/g-product-hero-grid';
import GQaa from '../components/ui/g-qaa';
import GSwiper from '../components/ui/g-swiper';
import { GTextGradient } from '../components/ui/g-text-gradient';
import { useGQuery } from '../hooks/use-g-query';
import { useTitle } from '../hooks/use-title';
import { IProduct } from '../models';
import { supabase } from '../supabase-client';

const PersonalDetails = () => {
  useTitle('Personal Details');
  let query = useGQuery();
  let location = useLocation();

  const l = query.get('l') ?? 'en';
  const slug = location.pathname + (l === 'km' ? '?l=km' : '');

  const { data, error } = useQuery(['personalbyslug_menus', slug, l], async () => {
    const { data } = await supabase
      .from<IProduct>('menus')
      .select('id,title,description,qaas(id,question,answer),images(id,src,alt)')
      .eq('slug', slug)
      .eq('lang', l)
      // @ts-ignore
      .order('orders', { foreignTable: 'images' })
      // @ts-ignore
      .order('orders', { foreignTable: 'qaas' })
      .single();

    return data;
  });

  if (error) return <h1>'An error has occured'</h1>;
  if (!data) return <GSpinner />;

  return (
    <>
      <GContainer>
        <GProductHeroGrid images={data.images} />

        <div className="px-2">
          <div className="flex items-start">
            <h1 className="pt-16 pb-4 text-xl font-extrabold text-center text-gray-900 dark:text-gray-100 sm:text-xl">
              <GTextGradient text={data.title} />
            </h1>
          </div>
          {data.description &&
            data?.description.map((d, i) => (
              <p key={`PersonalDetails${i}`} className="mb-2 text-center text-gray-500 dark:text-gray-400 md:text-left">
                {d}
              </p>
            ))}
          <div className="container mx-auto mt-6">
            <GQaa qaas={data.qaas || []} />
          </div>
        </div>

        <GSwiper />
      </GContainer>
    </>
  );
};

export default PersonalDetails;
