import { ChevronDownIcon } from "@heroicons/react/24/outline";
import cn from "clsx";
import { useRef, useState } from "react";
import { IFaqDetail } from "../../models";

export const GFaqs = ({ faqs }: { faqs: IFaqDetail[] }) => {
  return (
    <dl className="space-y-2 divide-y divide-gray-200 dark:divide-gray-700">
      {(faqs || []).map((faq) => (
        <FaqItem item={faq} />
      ))}
    </dl>
  );
};

export default GFaqs;

const FaqItem = ({ item }: { item: IFaqDetail }) => {
  const [visible, setVisible] = useState(false);
  const contentRef = useRef<HTMLParagraphElement>(null);
  return (
    <div key={item.id} className="pt-2">
      <dt className="text-lg">
        <button
          className="flex items-start justify-between w-full text-left text-gray-400"
          onClick={() => setVisible(!visible)}
        >
          <span className="font-medium text-gray-900 dark:text-gray-300">
            {item.question}
          </span>
          <span className="flex items-center ml-6 h-7">
            <ChevronDownIcon
              style={{
                rotate: visible ? "-180deg" : "0deg",
                transition: "rotate 0.5s ease-in-out",
              }}
              className={cn("h-6 w-6 ")}
              aria-hidden="true"
            />
          </span>
        </button>
      </dt>
      <p
        style={{
          maxHeight: visible ? `${contentRef.current?.scrollHeight}px` : "0px",
          opacity: visible ? 1 : 0,
          overflow: "hidden",
          transition: "max-height 0.5s ease-in-out ,opacity 0.5s ease-in-out",
        }}
        className="text-base text-gray-500 dark:text-gray-400 pr-12 mt-2"
        ref={contentRef}
      >
        {item.answer}
      </p>
    </div>
  );
};
