import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import cn from 'clsx';
import { IQaa } from '../../models';
import GList from './g-list';

export const GQaa = ({ qaas }: { qaas: IQaa[] }) => {
  return (
    <dl className="space-y-2 divide-y divide-gray-200 dark:divide-gray-700">
      {(qaas || []).map((qaa) => (
        <Disclosure as="div" key={qaa.id} className="pt-2">
          {({ open }) => (
            <>
              <dt className="text-lg">
                <Disclosure.Button className="flex items-start justify-between w-full text-left text-gray-400">
                  <span className="font-medium text-gray-900 dark:text-gray-300">{qaa.question}</span>
                  <span className="flex items-center ml-6 h-7">
                    <ChevronDownIcon className={cn(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')} aria-hidden="true" />
                  </span>
                </Disclosure.Button>
              </dt>
              <Disclosure.Panel as="dd" className="pr-12 mt-2">
                {qaa.answer.type === 'text' && <p className="text-base text-gray-500 dark:text-gray-400">{qaa.answer.data}</p>}
                {qaa.answer.type === 'list' && <GList items={qaa.answer?.data as string[]} />}
                {qaa.answer.type === 'listi' && <GList items={qaa.answer?.data as string[]} icon />}
                {/* {qaa.answer.type === 'text' && <p className="text-base text-gray-500 dark:text-gray-400">{qaa.answer}</p>}
                <p className="text-base text-gray-500 dark:text-gray-400">{qaa.answer}</p> */}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      ))}
    </dl>
  );
};

export default GQaa;
