import { useQuery } from '@tanstack/react-query';
import GSpinner from '../components/common/g-spinner';
import GContainer from '../components/ui/g-container';
import { useGQuery } from '../hooks/use-g-query';
import { useTitle } from '../hooks/use-title';
import { ICareer } from '../models';
import GMotivations from '../sections/careers/motivations';
import GOpenPositions from '../sections/careers/open-positions';
import { supabase } from '../supabase-client';

const Careers = () => {
  useTitle('Careers');
  let query = useGQuery();
  const l = query.get('l') ?? 'en';

  const { data, error } = useQuery(['careers', l], async () => {
    const { data } = await supabase
      .from<ICareer>('careers')
      .select('*,career_details(id,position,department,type,close_date,location,orders,slug,lang)')
      .eq('lang', l)
      // @ts-ignore
      .order('orders', { ascending: true, foreignTable: 'career_details' })
      .single();

    return data;
  });

  if (error) return <h1>'An error has occured'</h1>;
  if (!data) return <GSpinner />;

  return (
    <>
      <img src={data.image1} className="object-cover w-full h-3/6 md:h-screen" alt="career" />
      <GContainer>
        <GMotivations value={data.values} benefit={data.benefits} />
        <div className="pb-2 mt-8">
          <h3 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-3xl">{data.subtitle}</h3>
        </div>
        <GOpenPositions positions={data.career_details} />
      </GContainer>
    </>
  );
};

export default Careers;
