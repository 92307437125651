import { useQuery } from '@tanstack/react-query';
import { useCallback, useState } from 'react';
import Gallery from 'react-photo-gallery';
import GSpinner from '../components/common/g-spinner';
import GContainer from '../components/ui/g-container';
import GModalImage from '../components/ui/g-modal-image';
import { useGQuery } from '../hooks/use-g-query';
import { useTitle } from '../hooks/use-title';
import { ICsr, ICsrDetail } from '../models';
import { supabase } from '../supabase-client';

export function GCsrPhoto({ photos }: { photos: ICsrDetail[] }) {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  // @ts-ignore
  const openLightbox = useCallback((_event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const handelRight = () => {
    const totalLength = photos.length;
    if (currentImage + 1 >= totalLength) {
      setCurrentImage(0);
      return;
    }

    setCurrentImage(currentImage + 1);
  };

  const handelLeft = () => {
    const totalLength = photos.length;
    if (currentImage === 0) {
      setCurrentImage(totalLength - 1);
      return;
    }

    setCurrentImage(currentImage - 1);
  };

  return (
    <div>
      <Gallery photos={photos} onClick={openLightbox} />
      {viewerIsOpen ? <GModalImage open={viewerIsOpen} onClose={closeLightbox} onLeft={handelLeft} onRight={handelRight} src={photos[currentImage].src}></GModalImage> : null}
    </div>
  );
}
const Csr = () => {
  useTitle('CSR');
  let query = useGQuery();
  const l = query.get('l') ?? 'en';
  const { data, error } = useQuery(['csrs', l], async () => {
    const { data } = await supabase.from<ICsr>('csrs').select('*,csr_details(id,title,src,width,height)').eq('lang', l).single();

    return data;
  });

  if (error) return <h1>'An error has occured'</h1>;
  if (!data) return <GSpinner />;

  return (
    <>
      <img src={data.image1} alt="crs" />
      <GContainer>
        <h3 className="py-4 text-2xl font-extrabold tracking-tight text-center text-gray-900 dark:text-white sm:text-3xl">{data.title}</h3>
        <div className="px-2 my-3 space-y-2 text-lg text-center text-gray-700 dark:text-gray-400 md:text-left">
          <p>{data.description}</p>
        </div>
        <GCsrPhoto photos={data.csr_details} />
      </GContainer>
    </>
  );
};

export default Csr;
