import { CheckIcon } from "@heroicons/react/24/outline";
import { LoadScript } from "@react-google-maps/api";
import { useQuery } from "@tanstack/react-query";
import GSpinner from "../components/common/g-spinner";
import GCheck from "../components/ui/g-bg-icon";
import GContainer from "../components/ui/g-container";
import GMap from "../components/ui/g-map";
import { useGQuery } from "../hooks/use-g-query";
import { useTitle } from "../hooks/use-title";
import { GOOGLE_API_KEY } from "../libs/utils/constraints";
import { IClinic } from "../models";
import { supabase } from "../supabase-client";

const Clinics = () => {
  useTitle("Clinics");
  let query = useGQuery();
  const l = query.get("l") ?? "en";
  const { data, error } = useQuery(["clinics", l], async () => {
    const { data } = await supabase
      .from<IClinic>("clinics")
      .select("*,clinic_panel(id,title,lat,lng,description,map_link)")
      .eq("lang", l)
      // @ts-ignore
      .order("orders", { foreignTable: "clinic_panel" })
      .single();

    return data;
  });

  if (error) return <h1>'An error has occured'</h1>;
  if (!data) return <GSpinner />;

  return (
    <>
      <img src={data.image1} alt="claims" className="" />
      <GContainer>
        <div className="py-4 overflow-auto md:py-8">
          <div className="max-w-4xl mx-auto text-center">
            <h2 className="text-3xl font-extrabold text-gray-900 uppercase dark:text-white sm:text-4xl">
              {data.title}
            </h2>
            <p className="mt-3 text-xl text-gray-900 dark:text-white sm:mt-4">
              {data.subtitle}
            </p>
          </div>
        </div>
        <dl className="px-2 space-y-10">
          {data.qaa.map((item) => (
            <div key={item.label} className="relative">
              <dt>
                {/* <div className="absolute flex items-center justify-center w-12 h-12 text-white bg-indigo-500 rounded-md dark:bg-gray-700 dark:text-gray-300">
                  <CheckCircleIcon className="w-6 h-6" aria-hidden="true" />
                </div> */}
                <GCheck />
                <p className="ml-16 text-lg font-medium leading-6 text-gray-900 dark:text-gray-300">
                  {item.label}
                </p>
              </dt>
              <dd className="mt-2 ml-16 text-base text-gray-500 dark:text-gray-400">
                <p>{item.description.content}</p>
                <ul className="mt-6 space-y-4">
                  {item.description.list?.map((l, i) => (
                    <li key={`clinicqaa${i}`} className="flex space-x-3">
                      <CheckIcon
                        className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="text-sm text-gray-500 dark:text-gray-400">
                        {l}
                      </span>
                    </li>
                  ))}
                </ul>
              </dd>
            </div>
          ))}
        </dl>
        <div className="flex items-start">
          <h1 className="pt-16 pb-4 text-xl font-extrabold text-center text-gray-900 dark:text-white">
            {data.subtitle1}
          </h1>
        </div>
        <LoadScript googleMapsApiKey={GOOGLE_API_KEY ?? ""}>
          <GMap panels={data.clinic_panel} />
        </LoadScript>
      </GContainer>
    </>
  );
};

export default Clinics;
