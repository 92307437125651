import { ReactNode, useEffect } from 'react';

export interface GHeadersProps {
  className?: string;
  offset?: number;
  children?: ReactNode;
}

export const GHeaders = ({ children, className, offset = 100 }: GHeadersProps) => {
  useEffect(() => {
    const elementId = document.getElementById('header');
    document.addEventListener('scroll', () => {
      if (window.scrollY > offset) {
        elementId?.classList.add('shadow');
      } else {
        elementId?.classList.remove('shadow');
      }
    });
  }, [offset]);

  const c = ['sticky top-0 z-40'];
  if (className) c.push(className);

  return (
    <div id="header" className={c.join(' ')}>
      {children}
    </div>
  );
};

export default GHeaders;
