interface DividerProp {
  className?: string;
}

function Divider({
  className = "bg-gray-200 dark:bg-gray-700 h-[1px] w-full",
}: Readonly<DividerProp>) {
  return <div className={`${className}`} />;
}

export default Divider;
