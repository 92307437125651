import { Link } from 'react-router-dom';
import { useTitle } from '../hooks/use-title';

export default function NotFound() {
  useTitle('Page Not Found');
  return (
    <>
      <div className="flex flex-col items-center justify-center h-full min-h-screen px-1 mx-auto max-w-8xl md:px-6">
        <p className="items-center text-4xl font-extrabold sm:text-7xl">404</p>

        <div className="py-4 text-4xl font-extrabold tracking-tight sm:text-5xl">
          <span className="text-transparent bg-gradient-to-r from-blue-700 to-amber-500 bg-clip-text">PAGE NOT FOUND</span>
        </div>

        <p className="mt-1 text-base">Please check the URL in the address bar</p>
        <p className="mt-1 mb-4 text-base">and try again.</p>
        <div className="flex-col justify-center">
          <Link to="/" className="inline-flex items-center px-4 py-2 text-sm font-medium bg-gray-800 border border-transparent hover:bg-gray-700">
            BACK TO HOME PAGE
          </Link>
        </div>
      </div>
    </>
  );
}
