import { IconProp } from "../../@types/icon";

export const InfArrow = ({ width = 30, height = 30 }: IconProp) => {
  return (
    <svg width={width} height={height} viewBox="0 0 91 91" fill="none">
      <g clipPath="url(#clip0_11211_2)">
        <path
          d="M45.5 90.5C70.3528 90.5 90.5 70.3528 90.5 45.5C90.5 20.6472 70.3528 0.5 45.5 0.5C20.6472 0.5 0.5 20.6472 0.5 45.5C0.5 70.3528 20.6472 90.5 45.5 90.5Z"
          fill="#f47521"
        />
        <path
          d="M19.7402 20.45L45.5002 80.5599L71.2602 20.45C71.1702 20.51 71.0902 20.57 71.0002 20.62C55.5402 31.17 35.4502 31.17 20.0002 20.62C19.9102 20.56 19.8302 20.5 19.7402 20.45Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_11211_2">
          <rect width="91" height="91" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
