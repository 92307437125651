import { CalendarIcon, MapPinIcon, UsersIcon } from '@heroicons/react/24/outline';
import { format, parseISO } from 'date-fns';
import { Link } from 'react-router-dom';
import { ICareerDetail } from '../../models';

export const GOpenPositions = ({ positions }: { positions: ICareerDetail[] }) => {
  return (
    <div>
      <div className="overflow-hidden bg-gray-100 shadow dark:bg-gray-800 sm:rounded-md">
        <ul className="divide-y divide-gray-200 dark:divide-gray-700">
          {positions.map((p) => (
            <li key={p.id}>
              <Link to={`/careers/${p.slug}`} className="block hover:bg-gray-200 dark:hover:bg-gray-700">
                <div className="px-4 py-4 sm:px-6">
                  <div className="flex items-center justify-between">
                    <p className="text-sm font-medium truncate text-primary dark:text-gray-200">{p.position}</p>
                    <div className="flex flex-shrink-0 ml-2">
                      <p className="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full dark:bg-gray-700 dark:text-white">{p.type}</p>
                    </div>
                  </div>
                  <div className="mt-2 sm:flex sm:justify-between">
                    <div className="sm:flex">
                      <p className="flex items-center text-sm text-gray-500">
                        <UsersIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                        {p.department}
                      </p>
                      <p className="flex items-center mt-2 text-sm text-gray-500 sm:mt-0 sm:ml-6">
                        <MapPinIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                        {p.location}
                      </p>
                    </div>
                    <div className="flex items-center mt-2 text-sm text-gray-500 sm:mt-0">
                      <CalendarIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                      <p>
                        Closing on <time dateTime={format(parseISO(p.close_date), 'EEEE, MMMM dd, yyyy')}>{format(parseISO(p.close_date), 'dd-MMM-yyyy')}</time>
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default GOpenPositions;
