//text
// => All phone size 375px<size<640px

//'sm': '640px',
// => @media (min-width: 640px) { ... }

//'md': '768px',
// => @media (min-width: 768px) { ... }

//'lg': '1024px',
// => @media (min-width: 1024px) { ... }

//'xl': '1280px',
// => @media (min-width: 1280px) { ... }

//'2xl': '1536px',
// => @media (min-width: 1536px) { ... }

export const ResponsiveText = {
  headerText: "text-xl sm:text-2xl md:text-3xl xl:text-4xl 2xl:text-4xl",
  contentText:
    "text-base sm:text-base md:text-base lg:text-lg xl:text-lg 2xl:text-lg",
  subheaderText: "text-base sm:text-lg md:text-xl xl:text-2xl 2xl:text-2xl",
  footerLabelText: "text-base lg:text-lg",
  contactInfoText: "text-[16px] md:text-[16px] sm:text-[12px]",
  formCategoryText: "text-[10px] sm:text-[13px]",
};

export const ResponsivePadding = {
  contentPadding: "px-[20px] lg:px-0",
};

export const ResponsiveIcon = {
  iconSize:
    "h-[20px] w-[20px]  sm:h-[25px] sm:w-[25px] md:h-[30px] md:w-[30px] xl:h-[35px] xl:w-[35px]",
};
