import GSliders from '../../components/ui/g-sliders';
import { ITestimonial } from '../../models';
import GTestimonials from './testimonials';

export const GSliderTestimonials = ({ testimonial }: { testimonial: ITestimonial[] }) => {
  return (
    <GSliders>
      {testimonial &&
        testimonial.map((t: ITestimonial, i) => (
          <div key={`st${t.name}${i}`} className="keen-slider__slide h-[28rem]">
            <GTestimonials testimonail={t} />
          </div>
        ))}
    </GSliders>
  );
};

export default GSliderTestimonials;
