import { useQuery } from "@tanstack/react-query";
import GSpinner from "../components/common/g-spinner";
import GGridProducts from "../components/ui/g-grid-products";
import GSliders from "../components/ui/g-sliders";
import { useGQuery } from "../hooks/use-g-query";
import { useTitle } from "../hooks/use-title";
import { IHome } from "../models";
import GSliderTestimonials from "../sections/home/slides-testimonials";
import GWhyInfinity from "../sections/home/why-infinity";
import { supabase } from "../supabase-client";

export default function Home() {
  useTitle("Home");
  let query = useGQuery();
  const l = query.get("l") ?? "en";
  const { data, error } = useQuery(["home_get_home", l], async () => {
    const { data } = await supabase
      .rpc<IHome>("get_home", {
        l: l,
      })
      .single();
    return data;
  });

  if (error) return <h1>'An error has occured'</h1>;
  if (!data) return <GSpinner />;

  return (
    <>
      <div key={data.id}>
        <GSliders>
          {data.images.map((image: string, i: number) => (
            <div
              key={`homekey1${i}`}
              className=" keen-slider__slide lazy__slide"
            >
              <img className="w-full lg:h-[38rem]" src={image} alt="home1-en" />
            </div>
          ))}
        </GSliders>
        <div className="px-4 mx-auto mt-8 max-w-7xl sm:mt-16">
          <div className="text-center">
            <h1 className="text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white sm:text-4xl md:text-4xl">
              <span className="text-transparent uppercase bg-gradient-to-r from-primary to-amber-500 bg-clip-text">
                {data.corporate.title}
              </span>
            </h1>
            <p className="max-w-md mx-auto mt-3 text-base text-gray-500 sm:text-lg md:mt-5 md:max-w-5xl md:text-xl">
              {data.corporate.description}
            </p>
          </div>
          <div className="pt-4">
            <GGridProducts products={data.corporate?.children ?? []} />
          </div>
        </div>
        <div className="px-4 mx-auto my-16 max-w-7xl sm:mt-24">
          <div className="text-center">
            <h1 className="text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white sm:text-4xl md:text-4xl">
              <span className="text-transparent uppercase bg-gradient-to-r from-primary to-amber-500 bg-clip-text">
                {data.personal.title}
              </span>
            </h1>
            <p className="max-w-md mx-auto mt-3 text-base text-gray-500 sm:text-lg md:mt-5 md:max-w-5xl md:text-xl">
              {data.personal.description}
            </p>
          </div>

          <div className="pt-4">
            <GGridProducts products={data.personal?.children ?? []} />
          </div>
        </div>
        <section>
          <GSliders showDot={true}>
            {data.images2.map((image: string, i: number) => (
              <div
                key={`homekey1${i}`}
                className="lg:h-[38rem] keen-slider__slide"
              >
                <img className="w-full" src={image} alt="home2-en" />
              </div>
            ))}
          </GSliders>
        </section>
        <GWhyInfinity
          title={data.why_title}
          image={data.why_image}
          description={data.why_description}
        />
        <GSliderTestimonials testimonial={data.testimonails} />
      </div>
    </>
  );
}
