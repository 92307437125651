import { useQuery } from "@tanstack/react-query";
import cn from "clsx";
import { Link, useLocation } from "react-router-dom";
import { useGQuery } from "../../hooks/use-g-query";
import { IChildren } from "../../models";
import { supabase } from "../../supabase-client";
import GSpinner from "../common/g-spinner";

import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react";
import React, { useState } from "react";

export default function GSwiper() {
  let query = useGQuery();
  let location = useLocation();
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [loaded, setLoaded] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    loop: true,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
    breakpoints: {
      "(min-width: 640px)": {
        slides: { perView: 2, spacing: 5 },
      },
      "(min-width: 1000px)": {
        slides: { perView: 3, spacing: 10 },
      },
    },
    slides: { perView: 1 },
  });

  const l = query.get("l") ?? "en";
  const slug = location.pathname + (l === "km" ? "?l=km" : "");

  const { isLoading, error, data } = useQuery<IChildren[] | null>(
    ["get_menus_by_slug", slug],
    async () => {
      const { data } = await supabase.rpc("get_menus_by_slug", {
        s: slug,
      });

      return data;
    }
  );

  if (isLoading) return <GSpinner />;
  if (error) return <h1>An error has occurred</h1>;

  return (
    <>
      <div className="navigation-wrapper">
        <div ref={sliderRef} className="keen-slider">
          {data?.map((c, i) => (
            <GGridItem key={`GSwiper${i}`} item={c} />
          ))}
        </div>
        {loaded && instanceRef.current && (
          <>
            <Arrow
              left
              onClick={(e: any) =>
                e.stopPropagation() || instanceRef.current?.prev()
              }
              disabled={currentSlide === 0}
            />

            <Arrow
              onClick={(e: any) =>
                e.stopPropagation() || instanceRef.current?.next()
              }
              disabled={
                currentSlide ===
                instanceRef.current.track.details.slides.length - 1
              }
            />
          </>
        )}
      </div>
      {/* {loaded && instanceRef.current && (
        <div className="dots">
          {
            // @ts-ignore
            [...Array(instanceRef.current.track.details.slides.length).keys()].map((idx) => {
              return (
                <button
                  key={idx}
                  onClick={() => {
                    instanceRef.current?.moveToIdx(idx);
                  }}
                  className={'dot' + (currentSlide === idx ? ' bg-orange' : '')}
                ></button>
              );
            })
          }
        </div>
      )} */}
    </>
  );
}

function Arrow(props: {
  disabled: boolean;
  left?: boolean;
  onClick: (e: any) => void;
}) {
  const disabeld = props.disabled ? " arrow--disabled" : "";
  return (
    <svg
      onClick={props.onClick}
      className={cn(
        "fill-orange",
        `arrow ${props.left ? "arrow--left" : "arrow--right"} ${disabeld}`
      )}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      {props.left && (
        <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
      )}
      {!props.left && (
        <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
      )}
    </svg>
  );
}

const GGridItem = ({ item }: { item: IChildren }) => {
  return (
    <div className="flex items-center justify-center keen-slider__slide">
      <Link
        to={item.slug}
        className="flex items-start p-3 my-8 -m-3 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700"
      >
        <>
          <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 text-white bg-indigo-100 rounded-md dark:bg-gray-700 dark:text-gray-200 sm:h-12 sm:w-12">
            <img src={item.icon} alt="menu" className="h-9 w-9" />
          </div>
          <div
            className={cn(
              "ml-4",
              item?.short_description
                ? ""
                : "flex h-12 items-center justify-center"
            )}
          >
            <p className="text-base font-medium text-gray-900 dark:text-gray-200">
              {item.title}
            </p>
            {item?.short_description && (
              <p className="mt-1 text-xs text-gray-500 truncate dark:text-gray-400">
                {item.short_description}
              </p>
            )}
          </div>
        </>
      </Link>
    </div>
  );
};
