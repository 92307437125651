export interface GYouTubeProps {
  title?: string;
  uid?: string;
}

export function GYouTube({ uid = 'LzzDxDblA1Y', title = 'Hid and show file in flash by cmd' }: GYouTubeProps) {
  return (
    <div>
      <iframe allowFullScreen src={`https://www.youtube.com/embed/${uid}`} width="100%" height="100%" title={title}></iframe>
    </div>
  );
}

export default GYouTube;
