import { useQuery } from '@tanstack/react-query';
import { useTheme } from 'next-themes';
import { Link } from 'react-router-dom';
import { useGQuery } from '../../hooks/use-g-query';
import { IFooter } from '../../models';
import { supabase } from '../../supabase-client';
import { GFacebookIcon, GLinkedInIcon, GYouTubeIcon } from '../icons';
import GSpinner from './g-spinner';

export default function GFooter() {
  const { theme } = useTheme();
  let query = useGQuery();

  const l = query.get('l') ?? 'en';

  const { data, error } = useQuery(['footers', l], async () => {
    const { data } = await supabase.from<IFooter>('footers').select('*').eq('lang', l).single();

    return data;
  });

  if (error) return <h1>'An error has occured'</h1>;
  if (!data) return <GSpinner />;

  return (
    <footer className="bg-white dark:bg-gray-900" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="container px-4 py-12 mx-auto sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-8 xl:col-span-1">
            <Link to={l === 'km' ? '/?l=km' : '/'}>
              {theme === 'light' ? (
                <img
                  className="h-[126px] w-[110px] xl:h-[170px] xl:w-[160px]"
                  src="https://ncaevhwkfwxiofizozdx.supabase.co/storage/v1/object/public/logos/logo.png"
                  alt="Company name"
                />
              ) : (
                <img
                  className="h-[126px] w-[110px] xl:h-[170px] xl:w-[160px]"
                  src="https://ncaevhwkfwxiofizozdx.supabase.co/storage/v1/object/public/logos/logo-white.png"
                  alt="Company name"
                />
              )}
            </Link>
            <div className="flex space-x-6">
              {data.facebook && (
                <a href={data.facebook} className="text-gray-700 hover:text-gray-500 dark:text-gray-300" rel="noopener noreferrer" aria-label="Facebook" target="_blank">
                  <span className="sr-only">FaceBook</span>
                  <GFacebookIcon />
                </a>
              )}

              {data.linkedin && (
                <a href={data.linkedin} className="text-gray-700 hover:text-gray-500 dark:text-gray-300" rel="noopener noreferrer" aria-label="LinkedIn" target="_blank">
                  <span className="sr-only">LinkedIn</span>
                  <GLinkedInIcon />
                </a>
              )}

              {data.youtube && (
                <a href={data.youtube} className="text-gray-700 hover:text-gray-500 dark:text-gray-300" rel="noopener noreferrer" aria-label="YouTube" target="_blank">
                  <span className="sr-only">YouTube</span>
                  <GYouTubeIcon />
                </a>
              )}
            </div>
          </div>
          <div className="grid grid-cols-2 gap-8 mt-12 xl:col-span-2 xl:mt-0">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold tracking-wider text-gray-500 uppercase dark:text-gray-300">{data.solutions.title}</h3>
                <ul className="mt-4 space-y-4">
                  {data.solutions.items.map((item) => (
                    <li key={item.label}>
                      <Link to={item.slug} className="text-base text-gray-700 hover:text-gray-900 dark:text-gray-400">
                        {item.label}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold tracking-wider text-gray-500 uppercase dark:text-gray-300">{data.supports.title}</h3>
                <ul className="mt-4 space-y-4">
                  {data.supports.items.map((item) => (
                    <li key={item.label}>
                      <Link to={item.slug} className="text-base text-gray-700 hover:text-gray-900 dark:text-gray-400">
                        {item.label}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold tracking-wider text-gray-500 uppercase dark:text-gray-300">{data.company.title}</h3>
                <ul className="mt-4 space-y-4">
                  {data.company.items.map((item) => (
                    <li key={item.label}>
                      <Link to={item.slug} className="text-base text-gray-700 hover:text-gray-900 dark:text-gray-400">
                        {item.label}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-8 mt-12 border-t border-gray-200 dark:border-gray-700">
          <p className="text-base text-gray-700 dark:text-gray-300 xl:text-center">{data.copyright}</p>
        </div>
      </div>
    </footer>
  );
}
