import React from "react";
import GContainer from "../components/ui/g-container";
import {
  ResponsivePadding,
  ResponsiveText,
} from "../constants/responsive-class";

export default function PrivacyPolicy() {
  return (
    <GContainer>
      <div
        className={`${ResponsivePadding.contentPadding} max-w-fit mx-auto text-center mb-[10px] pt-[100px]`}
      >
        <h2
          className={`${ResponsiveText.headerText} font-extrabold text-transparent uppercase bg-gradient-to-r from-primary to-orange bg-clip-text`}
        >
          Privacy Policy
        </h2>
      </div>
      <div className="flex mt-[48px] flex-col">
        <h4
          className={`${ResponsiveText.subheaderText} font-semibold text-start`}
        >
          1. Privacy Policy
        </h4>
        <p className={`${ResponsiveText.contentText} mt-[24px]`}>
          We don’t collect any information from individuals who access our
          Website, except some standard information in cookies, where Cookies
          are small pieces of text sent to your browser by a website you visit
          and they help that website remember information about your visit,
          which can both make it easier to visit the site again and make the
          site more useful to you. Our website uses cookies to make your use of
          this Website more convenient. For example, we use cookies to store
          information between sessions and to maintain information about the
          information you have requested during your session.
        </p>
        <p className={`${ResponsiveText.contentText} mt-[24px]`}>
          Infinity General Insurance Plc. reserves the right to modify this
          Privacy Policy statement at any time. The modifications will be
          notified on the Web pages where those changes are made.
        </p>

        <h4
          className={`${ResponsiveText.subheaderText} font-semibold text-start mt-[48px]`}
        >
          2. Disclaimer
        </h4>
        <p className={`${ResponsiveText.contentText} mt-[24px]`}>
          All the materials are for information only. They intend to provide
          general information about products and services. For complete
          information on the terms, conditions and exclusions of insurance
          coverages or other products or services, please contact our sales
          specialist, or refer to the policy or service contract.
        </p>
        <p className={`${ResponsiveText.contentText} mt-[24px]`}>
          Links to other internet sites that are not maintained by Infinity
          General Insurance Plc. may be accessed through this Web site. Such
          external internet addresses contain information created, published,
          maintained or otherwise posted by organizations and entities
          independent of Infinity. Infinity is not responsible for the content
          of those sites, nor does it approve, endorse or certify information
          available at any external site or linked addresses contained herein.
        </p>

        <h4
          className={`${ResponsiveText.subheaderText} font-semibold text-start mt-[48px]`}
        >
          3. About this app
        </h4>
        <p className={`${ResponsiveText.contentText} mt-[24px]`}>
          Can be accessed by Infinity General Insurance Plc. customers anywhere
          and anytime to view general information of products and services. With
          the app, you will be able to find the needed information in the simple
          steps and hassle-free.
        </p>

        <h4
          className={`${ResponsiveText.subheaderText} font-semibold text-start mt-[48px]`}
        >
          4. Advantages
        </h4>
        <ul className="mt-[24px] list-disc pl-[20px]">
          <li className={`${ResponsiveText.contentText} mt-[10px]`}>
            Check insurance products information, including the cost of some
            products.
          </li>
          <li className={`${ResponsiveText.contentText} mt-[10px]`}>
            Get claim information, including panel garages and clinics.
          </li>
          <li className={`${ResponsiveText.contentText} mt-[10px]`}>
            Query to our team.
          </li>
        </ul>
      </div>
    </GContainer>
  );
}
