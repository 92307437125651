import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { IBenefit, IValue } from '../../models';

const GMotivations = ({ value, benefit }: { value: IValue; benefit: IBenefit }) => {
  return (
    <div className="overflow-hidden bg-gray-50 dark:bg-gray-900">
      <div className="relative max-w-xl px-4 mx-auto sm:px-6 lg:max-w-7xl lg:px-8">
        <svg
          className="absolute hidden transform -translate-x-1/2 left-full -translate-y-1/4 lg:block"
          width={404}
          height={784}
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7" x={0} y={0} width={20} height={20} patternUnits="userSpaceOnUse">
              <rect x={0} y={0} width={4} height={4} className="text-gray-200 dark:text-gray-700" fill="currentColor" />
            </pattern>
          </defs>
          <rect width={404} height={784} fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)" />
        </svg>

        <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:items-center lg:gap-8">
          <div className="relative">
            <span className="inline-flex items-center rounded bg-blue-100 px-2 py-0.5 text-xs font-medium uppercase text-blue-800 dark:bg-gray-700 dark:text-gray-100">
              {value.label}
            </span>
            <h3 className="text-2xl font-extrabold tracking-tight text-gray-900 dark:text-gray-100 sm:text-3xl">{value.title}</h3>
            <p className="mt-3 text-lg text-gray-500 dark:text-gray-400">{value.description}</p>
          </div>

          <div className="relative mt-10 -mx-4 lg:mt-0" aria-hidden="true">
            <svg className="absolute transform -translate-x-1/2 translate-y-16 left-1/2 lg:hidden" width={784} height={404} fill="none" viewBox="0 0 784 404">
              <defs>
                <pattern id="ca9667ae-9f92-4be7-abcb-9e3d727f2941" x={0} y={0} width={20} height={20} patternUnits="userSpaceOnUse">
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200 dark:text-gray-700" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={784} height={404} fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)" />
            </svg>
            <img className="relative object-cover mx-auto h-44 md:rounded" width={490} src={value.image} alt="" />
          </div>
        </div>

        <svg className="absolute hidden transform translate-x-1/2 translate-y-12 right-full lg:block" width={404} height={784} fill="none" viewBox="0 0 404 784" aria-hidden="true">
          <defs>
            <pattern id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d" x={0} y={0} width={20} height={20} patternUnits="userSpaceOnUse">
              <rect x={0} y={0} width={4} height={4} className="text-gray-200 dark:text-gray-700" fill="currentColor" />
            </pattern>
          </defs>
          <rect width={404} height={784} fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
        </svg>

        <div className="relative mt-12 sm:mt-16 lg:mt-24">
          <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:items-center lg:gap-8">
            <div className="lg:col-start-2">
              <span className="inline-flex items-center rounded bg-blue-100 px-2 py-0.5 text-xs font-medium uppercase text-blue-800 dark:bg-gray-700 dark:text-gray-100">
                {benefit.label}
              </span>
              <h3 className="text-2xl font-extrabold tracking-tight text-gray-900 dark:text-gray-100 sm:text-3xl">{benefit.title}</h3>
              <p className="mt-3 text-lg text-gray-500 dark:text-gray-400">{benefit.description.content}</p>

              <dl className="mt-10 space-y-10">
                {benefit.description.list?.map((item) => (
                  <div key={item.label} className="relative">
                    <dt>
                      <div className="absolute flex items-center justify-center w-12 h-12 text-white bg-indigo-500 rounded-md dark:bg-gray-700 dark:text-gray-200">
                        <CheckCircleIcon className="w-6 h-6" aria-hidden="true" />
                      </div>
                      <p className="ml-16 text-lg font-medium leading-6 text-gray-900 dark:text-gray-300">{item.label}</p>
                    </dt>
                    <dd className="mt-2 ml-16 text-base text-gray-500 dark:text-gray-400">{item.content}</dd>
                  </div>
                ))}
              </dl>
            </div>

            <div className="relative mt-10 -mx-4 lg:col-start-1 lg:mt-0">
              <svg className="absolute transform -translate-x-1/2 translate-y-16 left-1/2 lg:hidden" width={784} height={404} fill="none" viewBox="0 0 784 404" aria-hidden="true">
                <defs>
                  <pattern id="e80155a9-dfde-425a-b5ea-1f6fadd20131" x={0} y={0} width={20} height={20} patternUnits="userSpaceOnUse">
                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={784} height={404} fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)" />
              </svg>
              <img className="relative mx-auto rounded" width={490} src={benefit.image} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GMotivations;
