import { PaperClipIcon } from '@heroicons/react/24/outline';
import { useQuery } from '@tanstack/react-query';
import { format, formatDistanceToNow, parseISO } from 'date-fns';
import { useParams } from 'react-router-dom';
import GSpinner from '../components/common/g-spinner';
import GContainer from '../components/ui/g-container';
import GList from '../components/ui/g-list';
import { useGQuery } from '../hooks/use-g-query';
import { useTitle } from '../hooks/use-title';
import { INewsletterDetail } from '../models';
import { supabase } from '../supabase-client';

const NewsLettersDetails = () => {
  useTitle('NewsLetter Details');
  let query = useGQuery();
  let params = useParams();
  const l = query.get('l') ?? 'en';

  const id = params.slug ?? '';
  const { data, error } = useQuery(['newslettersdetails', l, id], async () => {
    const { data } = await supabase
      .from<INewsletterDetail>('newsletter_details')
      .select('id,title,slug,category,description,image1,created_at,attachfile,profiles(id,display_name,avatar_url)')
      .eq('lang', l)
      .eq('slug', id)
      .single();

    return data;
  });

  if (error) return <h1>'An error has occured'</h1>;
  if (!data) return <GSpinner />;

  return (
    <>
      <GContainer>
        <img className="object-cover w-full h-96" src={data.image1} alt="newsletters" />
        <div key={data.title} className="flex flex-col overflow-hidden shadow-lg">
          <div className="flex flex-col justify-between flex-1 p-6 bg-white dark:bg-gray-800">
            <div className="flex-1">
              <p className="text-sm font-medium text-indigo-600 dark:text-gray-400">
                <span className="px-2 py-1 text-xs font-medium text-green-800 uppercase bg-green-100 rounded dark:bg-gray-700 dark:text-gray-500">{data?.category}</span>
              </p>
              <span className="block mt-2">
                <p className="text-xl font-semibold text-gray-900 dark:text-gray-200">{data.title}</p>
                {data.description.type === 'text' && <p className="text-base text-gray-500 dark:text-gray-400">{data.description.data}</p>}
                {data.description.type === 'list' && <GList items={data.description.data as string[]} />}
              </span>
              {data?.attachfile && (
                <div className="mt-2 border border-gray-200 divide-y divide-gray-200 rounded-md">
                  <div key={data.attachfile.title} className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                    <div className="flex items-center flex-1 w-0">
                      <PaperClipIcon className="flex-shrink-0 w-5 h-5 text-gray-400" aria-hidden="true" />
                      <span className="flex-1 w-0 ml-2 truncate">{data.attachfile.title ?? 'Attach File'}</span>
                    </div>
                    <div className="flex-shrink-0 ml-4">
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href={data?.attachfile?.attachfile ?? 'NA'}
                        download={data?.attachfile?.title ?? 'Attach File'}
                        className="font-medium cursor-pointer text-primary hover:text-blue-500 dark:text-gray-400 dark:hover:text-gray-100"
                      >
                        Download
                      </a>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="flex items-center mt-6">
              <div className="flex-shrink-0">
                <span>
                  <span className="sr-only">{data.profiles.display_name}</span>
                  <img className="w-10 h-10 rounded" src={data.profiles.avatar_url} alt="" />
                </span>
              </div>
              <div className="ml-3">
                <p className="text-sm font-medium text-gray-900 dark:text-gray-300">
                  {/* <a href={data.profiles.id} className="hover:underline"></a> */}
                  {data.profiles.display_name}
                </p>
                <div className="flex space-x-1 text-sm text-gray-500 dark:text-gray-400">
                  <time dateTime={format(parseISO(data.created_at), 'EEEE, MMMM dd, yyyy')}>{format(parseISO(data.created_at), 'EEEE, MMMM dd, yyyy')}</time>
                  <span aria-hidden="true">&middot;</span>
                  <time dateTime={formatDistanceToNow(parseISO(data.created_at), { addSuffix: true })}>{formatDistanceToNow(parseISO(data.created_at), { addSuffix: true })}</time>
                </div>
              </div>
            </div>
          </div>
        </div>
      </GContainer>
    </>
  );
};

export default NewsLettersDetails;
