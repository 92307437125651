import { createClient } from '@supabase/supabase-js';

const supabaseUrl = 'https://ncaevhwkfwxiofizozdx.supabase.co';
const supabaseAnonKey =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im5jYWV2aHdrZnd4aW9maXpvemR4Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NDY3OTc2NDQsImV4cCI6MTk2MjM3MzY0NH0.4A1JeilsyHY8zRKSTEYZQ4-39sprsuWgLX2ut4Rf-P0';

const options = {
  schema: 'public',
  headers: { 'x-gmana-copy-right': 'https://service.gmana.co/' },
  autoRefreshToken: true,
  persistSession: true,
  detectSessionInUrl: true,
};

export const supabase = createClient(supabaseUrl, supabaseAnonKey, options);
