import { PaperClipIcon } from '@heroicons/react/24/outline';
import { useQuery } from '@tanstack/react-query';
import { format, formatDistanceToNow, parseISO } from 'date-fns';
import { Link } from 'react-router-dom';
import GSpinner from '../components/common/g-spinner';
import GContainer from '../components/ui/g-container';
import { useGQuery } from '../hooks/use-g-query';
import { useTitle } from '../hooks/use-title';
import { INewslatter } from '../models';
import { supabase } from '../supabase-client';

const NewsLetters = () => {
  useTitle('Newsletters');
  let query = useGQuery();
  const l = query.get('l') ?? 'en';
  const { data, error } = useQuery(['newsletters', l], async () => {
    const { data } = await supabase
      .from<INewslatter>('newsletters')
      .select('*,newsletter_details(id,title,slug,category,excerpt,description,image1,created_at,attachfile,profiles(id,display_name,avatar_url))')
      .eq('lang', l)
      // @ts-ignore
      .order('orders', { foreignTable: 'newsletter_details' })
      .single();

    return data;
  });

  if (error) return <h1>'An error has occured'</h1>;
  if (!data) return <GSpinner />;

  return (
    <>
      <img src={data.image1} alt="newsletters" />
      <GContainer>
        <div className="relative px-4 pt-2 pb-20 bg-gray-50 dark:bg-gray-900 sm:px-6 lg:px-8 lg:pt-2 lg:pb-28">
          <div className="absolute inset-0">
            <div className="bg-white h-1/3 dark:bg-gray-900 sm:h-2/3" />
          </div>
          <div className="relative mx-auto max-w-7xl">
            <div className="mt-4 text-center">
              <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white sm:text-4xl">{data.title}</h2>
              <p className="max-w-2xl mx-auto mt-3 text-xl text-gray-500 dark:text-gray-400 sm:mt-4">{data.description}</p>
            </div>
            <div className="grid max-w-lg gap-5 mx-auto mt-12 lg:max-w-none lg:grid-cols-3">
              {data.newsletter_details.map((post) => (
                <div key={post.title} className="flex flex-col overflow-hidden rounded-lg shadow-lg">
                  <div className="flex-shrink-0">
                    <img className="object-cover w-full h-48" src={post.image1} alt="" />
                  </div>
                  <div className="flex flex-col justify-between flex-1 p-6 bg-white dark:bg-gray-800">
                    <div className="flex-1">
                      <p className="text-sm font-medium text-indigo-600 dark:text-gray-400">
                        <span className="px-2 py-1 text-xs font-medium text-green-800 uppercase bg-green-100 rounded dark:bg-gray-700 dark:text-gray-500">{post?.category}</span>
                      </p>
                      <Link to={`/newsletters/${post.slug}`} className="block mt-2">
                        <p className="text-xl font-semibold text-gray-900 dark:text-gray-200">{post.title}</p>
                        <p className="mt-3 text-base text-gray-500 line-clamp-3">{post.excerpt}</p>
                      </Link>
                      {post?.attachfile && (
                        <div className="mt-2 border border-gray-200 divide-y divide-gray-200 rounded-md">
                          <div key={post.attachfile.title} className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                            <div className="flex items-center flex-1 w-0">
                              <PaperClipIcon className="flex-shrink-0 w-5 h-5 text-gray-400" aria-hidden="true" />
                              <span className="flex-1 w-0 ml-2 truncate">{post.attachfile.title ?? 'Attach File'}</span>
                            </div>
                            <div className="flex-shrink-0 ml-4">
                              <a
                                rel="noopener noreferrer"
                                target="_blank"
                                href={post?.attachfile?.attachfile ?? 'NA'}
                                download={post?.attachfile?.title ?? 'Attach File'}
                                className="font-medium cursor-pointer text-primary hover:text-blue-500 dark:text-gray-400 dark:hover:text-gray-100"
                              >
                                Download
                              </a>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="flex items-center mt-6">
                      <div className="flex-shrink-0">
                        <span className="sr-only">{post.profiles.display_name}</span>
                        <img className="w-10 h-10 rounded" src={post.profiles.avatar_url} alt="" />
                      </div>
                      <div className="ml-3">
                        <p className="text-sm font-medium text-gray-900 dark:text-gray-300">{post.profiles.display_name}</p>
                        <div className="flex space-x-1 text-sm text-gray-500 dark:text-gray-400">
                          <time dateTime={format(parseISO(post.created_at), 'EEEE, MMMM dd, yyyy')}>{format(parseISO(post.created_at), 'EEEE, MMMM dd, yyyy')}</time>
                          <span aria-hidden="true">&middot;</span>
                          <time dateTime={formatDistanceToNow(parseISO(post.created_at), { addSuffix: true })}>
                            {formatDistanceToNow(parseISO(post.created_at), { addSuffix: true })}
                          </time>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </GContainer>
    </>
  );
};

export default NewsLetters;
