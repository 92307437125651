import axios, { isAxiosError } from "axios";
import { API_SUCCSESS } from "../../constants/api";

interface ApiConfig {
  url: string;
  params?: object;
  method?: "GET" | "POST" | "PATCH" | "DELETE" | "PUT";
  data?: object | null;
  unwrap?: boolean;
}

export const ApiService = async ({
  url,
  params = {},
  method = "GET",
  data = null,
  unwrap = false,
}: ApiConfig) => {
  try {
    const response = await axios.request({
      url,
      params,
      data,
      method,
    });
    if (
      API_SUCCSESS.includes(response.data.status) ||
      API_SUCCSESS.includes(response.status) ||
      response?.data?.Success
    ) {
      return handleResponseSuccess(response);
    } else {
      throw response;
    }
  } catch (error: any) {
    const { code, status } = isAxiosError(error) ? error.toJSON() : error;
    const errorStatus = error?.response?.status ?? status;
    const errorCode = code ?? error.name;
    const errorMessage = error.message;
    const errorData = error?.response ?? error?.data;

    const errorObject = {
      status: errorStatus,
      code: errorCode,
      message: errorMessage,
      data: errorData,
    };
    if (unwrap) {
      throw { error: errorObject, meta: error };
    } else {
      return { error: errorObject, meta: error };
    }
  }
};

const handleResponseSuccess: any = ({ data, meta }: any) => ({
  data: data.data,
  meta,
});
