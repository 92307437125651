/* eslint-disable jsx-a11y/anchor-is-valid */
import { useQuery } from '@tanstack/react-query';
import Marquee from 'react-fast-marquee';
import { IClient } from '../../models';
import { supabase } from '../../supabase-client';
import GSpinner from '../common/g-spinner';
import GContainer from './g-container';

const GClientMarquee = () => {
  const { data, error } = useQuery(['client_marquee'], async () => {
    const { data } = await supabase.from<IClient>('clients').select('id, image, alt,href');

    return data;
  });

  if (error) return <h1>'An error has occured'</h1>;
  if (!data) return <GSpinner />;

  return (
    <section className="my-5">
      <GContainer>
        <Marquee gradient={false} pauseOnHover pauseOnClick>
          {data.map((d) => (
            <div key={d.id}>
              <a className="mr-[-.0625rem] block py-4 px-2" href={d.href} target="_blank" rel="noopener noreferrer">
                <img className="block mx-auto" src={d.image} width="165px" height="90px" alt={d.alt} />
              </a>
            </div>
          ))}
        </Marquee>
      </GContainer>
    </section>
  );
};

export default GClientMarquee;
