import { FC } from "react";

interface InputProp {
  label?: string;
  isRequired: boolean;
  inputClassName?: string;
  multiline?: boolean;
  name: string;
  type?: string;
}

const Input: FC<InputProp> = ({
  label,
  isRequired,
  inputClassName = "",
  multiline = false,
  name,
  type = "text",
}) => {
  return (
    <div className="flex flex-1 flex-col">
      {multiline ? (
        <textarea
          name={name}
          required={isRequired}
          className={`text-black dark:text-white font-medium text-[14px] border-transparent bg-gray-100 dark:bg-gray-600 rounded-[5px] ${inputClassName}`}
        />
      ) : (
        <input
          name={name}
          required={isRequired}
          type={type}
          className={`text-black dark:text-white font-medium text-[14px] border-transparent bg-gray-100 dark:bg-gray-600 rounded-[5px] ${inputClassName}`}
        />
      )}
      {label && (
        <p className="text-left text-[14px] dark:text-gray-400 text-gray-600 font-medium">
          {label}
        </p>
      )}
    </div>
  );
};

export default Input;
