import { ChangeEvent, FC, FormEvent, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Input from "../../components/common/input/input";
import { ApiService } from "../../libs/utils/api-helper";
import { EndPoints } from "../../libs/utils/constraints";
import { IComplaint } from "../../models/complaint";

interface ComplaintSubmitFormProp {
  data: IComplaint;
}

const GSSwal = withReactContent(Swal);
const alertContent = () => {
  GSSwal.fire({
    title: "Thank You!",
    text: "We appreciate your feedback and will get back to you as soon as possible",
    icon: "success",
    timer: 5000,
    timerProgressBar: true,
    showConfirmButton: false,
  });
};

const ComplaintSubmitForm: FC<ComplaintSubmitFormProp> = ({ data }) => {
  const [fileNum, setFileNum] = useState(0);

  const submit = async (e: FormEvent<HTMLFormElement | undefined>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    try {
      const response = await ApiService({
        url: EndPoints.complaint,
        data: formData,
        method: "POST",
        unwrap: true,
      });
      if (response) {
        alertContent();
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement> | undefined) => {
    setFileNum(e?.target.files?.length ?? 0);
  };

  return (
    <>
      <h2 className="text-2xl font-extrabold text-orange uppercase dark:text-orange sm:text-2xl">
        {data.send_us_a_message}
      </h2>
      <form
        className="mt-5 grid grid-cols-12 gap-[15px] lg:gap-5 px-[20px]"
        onSubmit={submit}
      >
        <div className="col-span-12 lg:col-span-6">
          <h2 className="text-left flex font-medium text-sm lg:text-base">
            {data.name_label}
            <p className="text-red-600">*</p>
          </h2>
          <Input
            label={data.first_name_label}
            isRequired={true}
            name={"first_name"}
          />
        </div>
        <div className="col-span-12 lg:col-span-6">
          <h2 className="text-left lg:flex font-medium text-sm lg:text-base hidden ">
            ‎
          </h2>
          <Input
            label={data.last_name_label}
            isRequired={true}
            name={"last_name"}
          />
        </div>
        <div className="col-span-12 lg:col-span-6">
          <h2 className="text-left flex font-medium text-sm lg:text-base">
            {data.email_label}
            <p className="text-red-600">*</p>
          </h2>
          <Input isRequired={true} name={"email"} type="email" />
        </div>
        <div className="col-span-12 lg:col-span-6">
          <h2 className="text-left flex font-medium text-sm lg:text-base">
            {data.phone_number_label}
          </h2>
          <Input isRequired={true} name={"phone_number"} />
        </div>
        <div className="col-span-12 ">
          <h2 className="text-left flex font-medium text-sm lg:text-base">
            {data.company_name_label}
          </h2>
          <Input isRequired={false} name={"company_name"} />
          <div className="mt-5 flex">
            <label
              htmlFor="file-upload"
              className="font-medium self-left text-[14px] underline text-black dark:text-white"
            >
              {data.upload_label}
              <span className="text-red-500">*</span>
            </label>
            {fileNum > 0 && (
              <span className="text-gray-500 ml-[10px] font-medium self-left text-[14px] dark:text-white">
                {fileNum} files selected
              </span>
            )}
            <input
              onChange={handleFileChange}
              multiple
              name={"complaint_files"}
              id="file-upload"
              type="file"
              className="hidden"
              accept=".jpg,.jpeg,.png,.pdf,.docx"
              required={true}
            />
          </div>
        </div>
        <div className="col-span-12 ">
          <h2 className="text-left flex font-medium text-sm lg:text-base">
            {data.your_message_label} <p className="text-red-600">*</p>
          </h2>
          <Input
            name={"message"}
            isRequired={true}
            multiline={true}
            inputClassName="h-[200px]"
          />
        </div>
        <div className="col-span-12 ">
          <button
            className="bg-orange w-[200px] h-[45px] rounded-[10px]"
            type="submit"
          >
            <h2 className="text-white text-[20px] font-medium">
              {data.button_label}
            </h2>
          </button>
        </div>
      </form>
    </>
  );
};

export default ComplaintSubmitForm;
